export default function $$dialogBase({
  id,
  size = false,
  theme = false,
  slot
}) {
  const classNames = [
    "c-dialog",
    size && `c-dialog--${size}`,
    theme && `c-dialog--${theme}`
  ]
    .filter(Boolean)
    .join(" ");

  return /* html */ `
    <div id="${id}" class="${classNames}">
      <div class="c-dialog__bar" data-layout="end middle">
        <button class="c-button c-button--close-lg c-dialog__close-btn" data-close-dialog>
            <svg class="c-button__icon ai ai-close-hairline" role="presentation">
                <use xlink:href="#ai-close-hairline"></use>
            </svg>
            <span class="u-sr-only">Close Dialog</span>
        </button>
      </div>
      ${slot}
    </div>
  `;
}

window.XhrHelper =
  handleError: (xhr) ->
    # Default jsonError for empty or non-JSON response
    jsonError = { message: 'Oops, something went wrong on our side. Please try again or contact us for help' }

    # Error message guaranteeed to exist in this attribute and will be friendly for end users
    message = @buildMessage(xhr)

    # Details on the error response, always as a JSON object so key/val pairs if JSON, or a single attribute `response` with the plaintext/HTML response.
    error = @buildError(xhr)
    
    return {
      message: message,
      error: error,
      status: xhr.status
    }

  buildMessage: (xhr) ->
    if xhr.status == 0
      # No HTTP response (e.g., network failures)
      return 'Oops, we could not process your request due to a network failure. Are you still connected to the Internet?'
    else if xhr.status >= 500
      # 5xx error
      return 'Oops, something went wrong on our side. Please try again or contact us for help.'
    else
      # 4xx error
      return 'Sorry, we could not complete your request. Please check the errors.'

  buildError: (xhr) ->
    if xhr.responseJSON
      if xhr.responseJSON.error
        # JSON response in an expected format
        return xhr.responseJSON.error
      else
        # JSON response in an unexpected format
        return xhr.responseJSON
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
/* eslint-disable func-names */

$(document).ready(function() {
  function closeModal() {
    $('#downgrade-confirmation')
      .parents('.fancybox-wrap')
      .find('[data-close-dialog]')
      .trigger('click');
  }

  function requestDowngradeConfirmation() {
    $('#downgrade-confirmation').removeClass('u-hidden');
    $.fancybox({
      href: '#downgrade-confirmation',
      type: 'inline',
      beforeShow() {
        $('[data-close-dialog]').on('click', function() {
          $.fancybox.close();
        });
      },
      afterClose() {
        $('[data-close-dialog]').off('click');
      },
    });

    $('[data-cancel-downgrade]').on('click', function(e) {
      e.preventDefault();
      closeModal();
    });

    $('[data-confirm-downgrade]').on('click', function(e) {
      e.preventDefault();

      // Ensure the price is set to 0
      const form = $('#change_package_form');
      $('#final_price', form).val(0);
      $('#connections_amount, #channels_amount, #messages_amount', form).val('0');

      form.trigger('submit');

      closeModal();
    });
  }

  $('[data-downgrade-link]').on('click', function(e) {
    e.preventDefault();
    requestDowngradeConfirmation();
  });
});

/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable func-names */

$(document)
  .on('ajax:error', '#new_session', function(event, data, status, error) {
    let responseInJSON;
    try {
      responseInJSON = JSON.parse(data.responseText) || {};
    } catch (e) {
      responseInJSON = {};
    }

    error = responseInJSON.full_error || responseInJSON.error;

    let errorMessage = '';
    if (data.status === 429) {
      errorMessage = 'Too many incorrect login attempts. Please wait 5 minutes and try again.';
    } else {
      errorMessage = error;
      if (errorMessage.indexOf('disabled') === -1 && errorMessage.indexOf('locked') === -1) {
        errorMessage += `, or <a href="/users/password/new" class="ui-link">reset your password</a>.`;
      }
    }

    return $('#login-box [data-dialog-msgbox]').flashMessages({
      template: msgboxerror,
      message: errorMessage,
      afterShow() {
        return $.fancybox.reposition();
      },
    });
  })
  .on('ajax:success', '#new_session', function(event, data, status, xhr) {
    // delegate redirect to ajax:complete
    return $(this).data('redirect', true);
  });

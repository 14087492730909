$(document)
  .on('ajax:error', '#new_password', (_, data) => {
    let errors;

    try {
      [errors] = JSON.parse(data.responseText).errors.email;
    } catch {
      errors =
        "Oops, something went wrong on our side.  Please try again or <a href='/contact'>contact us</a> for help";
    }

    const email = $('#new_password_user_email').val();

    if (errors === 'not found') {
      errors = `We could not find an account with the email address <b>${email}</b>. Are you sure you registered with this email?`;
    }

    $('#forgot-password-box [data-dialog-msgbox]').flashMessages({
      message: errors,
      template: window.msgboxerror,
    });
  })

  .on('ajax:success', '#new_password', () => {
    const email = $('#new_password_user_email').val();
    const msg = `Instructions to reset your password have been sent to <b>${email}</b>.`;
    $('#forgot-password-box .js-hide-on-success').hide();
    $('#forgot-password-box .js-show-on-success').show();
    $('#forgot-password-box [data-dialog-msgbox]').flashMessages({
      message: msg,
      template: window.msgboxnotice,
    });
  })

  .on('click', '#forgot-password-box [data-close-dialog]', () => $.fancybox.close());

/* globals MobileHelpers, isMobile */

window.MobileHelpers = {
  /* only visible when in mobile view */
  narrowScreen() {
    if (!this.$window) {
      this.$window = $(window);
    }
    return this.$window.width() <= 640; /* Any update to this value must be replicated in mobile/framework.css */
  },
  mobileOptimised() {
    if (!this.$body) {
      this.$body = $('body');
    }
    return !this.$body.hasClass('not-mobile-optimised');
  },
  usingMobileView() {
    return MobileHelpers.narrowScreen() && MobileHelpers.mobileOptimised();
  },
  /*
    allow adding and removing event listeners to cancel events
    http://stackoverflow.com/questions/10444077/javascript-removeeventlistener-not-working
  */
  preventDefault(event) {
    event.preventDefault();
  },
  /*
    All credits to this man :raised_hands:
    http://stackoverflow.com/a/22041340/2801012

    tl;dr
    overflow: hidden doesn't work on ios safari
    when scrolling on overlaid content the body (site) content scroll as well
    triggering native browser bars messing up formatting (modals/sidebars/drawers)
  */
  preventDocumentTouchScrolling(bool) {
    if (bool === true) {
      document.addEventListener('touchmove', MobileHelpers.preventDefault, false);
    } else {
      document.removeEventListener('touchmove', MobileHelpers.preventDefault, false);
    }
  },
  addiOSOverlayScrollingFix(elem) {
    const $body = $(document.body);

    // Check if we should allow scrolling up or down
    // eslint-disable-next-line func-names
    $body.on('touchstart', elem, function(e) {
      // If the element is scrollable (content overflows), then...
      if (this.scrollHeight !== this.clientHeight) {
        // If we're at the top, scroll down one pixel to allow scrolling up
        if (this.scrollTop === 0) {
          this.scrollTop = 1;
        }
        // If we're at the bottom, scroll up one pixel to allow scrolling down
        if (this.scrollTop === this.scrollHeight - this.clientHeight) {
          this.scrollTop = this.scrollHeight - this.clientHeight - 1;
        }
      }
      // Check if we can scroll
      this.allowUp = this.scrollTop > 0;
      this.allowDown = this.scrollTop < this.scrollHeight - this.clientHeight;
      this.lastY = e.originalEvent.pageY;
    });

    // eslint-disable-next-line func-names
    $body.on('touchmove', elem, function(e) {
      const event = e.originalEvent;
      const up = event.pageY > this.lastY;
      const down = !up;
      this.lastY = event.pageY;

      if ((up && this.allowUp) || (down && this.allowDown)) {
        event.stopPropagation();
      } else {
        event.preventDefault();
      }
    });
  },
  // Detect mobile devices and browsers
  IsMobile: {
    Android() {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry() {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera() {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows() {
      return navigator.userAgent.match(/IEMobile/i);
    },
    any() {
      return isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows();
    },
    isSafari: !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/),
  },
  scrollToCurrentTab(selector) {
    const tablists = document.querySelectorAll(selector);

    if (tablists.length < 0) {
      return;
    }

    [].slice.call(tablists).forEach(tablist => {
      const self = tablist;
      const activeTab = self.querySelector('.is-active');
      const selfDim = self.getBoundingClientRect();
      const activeTabDim = activeTab.getBoundingClientRect();
      const activeTabW =
        activeTabDim.width + parseInt(window.getComputedStyle(activeTab).getPropertyValue('margin-right'), 10);

      // if container not scrollable cancel
      if (self.scrollWidth <= selfDim.width) {
        return;
      }

      // scroll to topLeft of active tab
      self.scrollLeft += activeTab.offsetLeft;

      // Center it
      if (self.scrollLeft < self.scrollWidth / 2) {
        self.scrollLeft -= selfDim.width / 2 - activeTabW / 2;
      }
    });
  },
};

/**
 * @desc There's still a lot of inline scripts that depends
 * on jquery beeing globally availble on window.
 *
 * @note extracted to file because import statements are hoisted
 * to top of the file, making inline assignments like these not work
 * for dependent modules
 *
 * @link https://stackoverflow.com/questions/29080148/expose-jquery-to-real-window-object-with-webpack
 */

import jQuery from 'jquery';

window.jQuery = jQuery;
window.$ = jQuery;

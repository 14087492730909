# Enable burger nav menu when visible
$window = $(window)
$body = $('body')
$header= $('[data-js-header]')
$headerNav = $('[data-js-header-nav]')
$headerNavToggler = $('[data-js-header-nav-toggler]')
$documentation = $('body.page-documentation')

hideBurgerMenu = () ->
  $body.removeClass('header-nav-is-open')
  $headerNav.removeClass('is-open')
  # Enable Scroll on Document again
  MobileHelpers.preventDocumentTouchScrolling(false);

showBurgerMenu = () ->
  $body.addClass('header-nav-is-open')
  $headerNav.addClass('is-open')
  # Disable Scroll event on document
  MobileHelpers.preventDocumentTouchScrolling(true);
  # Extra effort for iOS safari quirks
  if MobileHelpers.IsMobile.iOS() and MobileHelpers.IsMobile.isSafari
    MobileHelpers.addiOSOverlayScrollingFix '[data-js-scrollable-ios-fix]'

$headerNavToggler.on 'click', () ->
  if $body.hasClass('header-nav-is-open')
    hideBurgerMenu()
  else
    showBurgerMenu()

# recalc offsets on resize
# ex: small tablet moving from portrait to landscape or vice-versa
recalculateOffsetsFn = debounce((->
  headerHeight = $('[data-js-header]').outerHeight()
  isMobile =  MobileHelpers.usingMobileView()

  if isMobile
    headerHeight = headerHeight + 10
  else if $header.hasClass('header-wrap-collapsed')
    headerHeight = headerHeight + 14

  if Navigation.options.isMobile != isMobile
    Navigation.options.isMobile = isMobile

  DocMenus?.setCurSection()
  Navigation.options.offset = headerHeight;
), 500)


$window.on 'resize', () ->
  if $documentation.length > 0
    recalculateOffsetsFn()

  if $window.innerWidth() >= 992
    hideBurgerMenu()


/**
 * Codemirror boot file
 * Loads styles, modes and addons while exposing (forwarding) CodeMirror
 * default export. This is manifest file to keep having to import this
 * list in every dependend module.
 */
// base styles
import "./codemirror.scss";

// core and language modes
import CodeMirror from "codemirror";
import "codemirror/addon/runmode/runmode";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/clike/clike";
import "codemirror/mode/python/python";
import "codemirror/mode/php/php";
import "codemirror/mode/ruby/ruby";
import "codemirror/mode/swift/swift";
import "codemirror/mode/go/go";
import "codemirror/mode/shell/shell";
import "codemirror/mode/htmlmixed/htmlmixed";
import "codemirror/mode/xml/xml";

export default CodeMirror;

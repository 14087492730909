/**
 * @desc inject in code snippets to inform the presence
 * of demo API keys
 * @note "html" comment for syntax highlighting only
 */
export default function $$demoKeysHint() {
  const tooltipTitle = `
    This code example uses a temporary key that is rate limited and expires
    in 4 hours. Sign in to Ably to use your API keys instead.
  `;

  return /* html */ `
    <div class="c-codebox__demo-keys-hint c-codebox__float-top-right"
      data-js="demo-keys-hint"
      data-toggle="tooltip"
      data-container="body"
      title="${tooltipTitle}"
    >
      <span class="faded">Api Key:&nbsp;</span>
      <strong class="u-m-r-tiny">Demo Only</strong>
      <svg class="ai ai-question-o-circle">
        <use xlink:href="#ai-question-o-circle"></use>
      </svg>
    </div>
  `;
}

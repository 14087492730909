/* eslint-disable prefer-rest-params, no-param-reassign */
/**
 * @desc excutes fn only once using closure and control variable
 * @param {Function} fn - function to call
 * @param {Object} context - to bind
 * @author David Walsh <https://davidwalsh.name/javascript-once>
 */
export const once = (fn, context) => {
  let result;
  return function() {
    if (fn) {
      result = fn.apply(context || this, arguments);
      fn = null;
    }
    return result;
  };
};

# Convenient version of setTimeout for Coffeescript
window.delay = (ms, func) -> setTimeout func, ms

# Provides an "easy" way to display alerts, such as Alert.danger(message);
# [deprecated] To close, trigger the custom 'close' event: $('.alert').trigger('close')

# [UPDATE] to close, trigger the custom 'close' event: $[data-alert-type].trigger('close');
pageScope = $('body').data('scope')
window.Alert =
  shiftContent: (direction, alert) ->
    main = $('[data-main]')
    mainFooter = $('[data-main-footer]')
    headerElem = $('[data-js-header]')
    alertSize = alert.outerHeight(true)

    if pageScope && pageScope.includes 'cameleon-header'
      return
    else if direction is 'up'
      main.css("margin-top", "0px")
    else
      alertSize += $('.c-banner--announcement').height() if $('.c-banner--announcement').length
      main.css("margin-top", alertSize)

  render: (params) ->
    self = this
    alertsContainer = $('[data-js-header-alerts-container]')
    closeId = ''
    if params.buttonId && params.buttonId != ""
      closeId = ' id="' + params.buttonId + '-close" '

    if params.msg && params.msg != ""
      close = $('<button type="button" ' + closeId + ' class="c-button c-alert__close-button" data-dismiss="alert" aria-label="Close">\
          <svg class="c-button__icon ai ai-close-rounded" role="presentation">\
             <use xlink:href="#ai-close-rounded"></use>\
          </svg>\
        </button>')

      alert = $('<div/>', {
        class: "c-alert", 'data-alert-type':  params.type,
        'data-embeddable-in-dialog': params.embeddableInDialog || true,
        'data-alert-stats': params.isStats == true,
        id: params.buttonId,
        html: $('<div class="c-alert__msg">')
                .html(params.msg)
                .append(close)
      })

      if self.doIhaveBrotherAlerts(params.type)
        $("[data-alert-type='#{params.type}']").each ->
          self.closeAlert($(this), false);

        alertsContainer.append alert
        alert.addClass('is-shaking') #slide from bellow nav
        alert.one 'animationend webkitAnimationEnd oanimationend MSAnimationEnd', (e) ->
          $(this).removeClass('is-shaking')
      else
        alert.addClass('is-hidden') #slide from bellow nav
        alertsContainer.append alert
        @shiftContent 'down', alert unless params.useOverlay
        delay 5, -> alert.removeClass('is-hidden') #still figuring out but it's needed

      # attach event handlers to close button
      close.on 'click', ->
        self.closeAlert(alert, true);
        params.onClosed() if params.onClosed

      if params.autoHide
        delay 8000, -> close.trigger('click')

      if params.backgroundColor
        alert.css('backgroundColor', params.backgroundColor)

      if MobileHelpers.usingMobileView() && ((params.type == 'warning') || (params.type == 'error'))
        $root = $('html, body')
        $root.animate({ scrollTop: 0 }, 250)

  success: (text, params = {}) ->
    @render $.extend { msg: text, type: 'success', autoHide: true }, params
  info: (text, params = {}) ->
    @render $.extend { msg: text, type: 'info', autoHide: true }, params
  warning: (text, params = {}) ->
    @render $.extend { msg: text, type: 'warning' }, params
  danger: (text, params = {}) ->
    @render $.extend { msg: text, type: 'error' }, params
  error: (text, params = {}) -> @danger(text, params)

  closeAlert: (alert, doYouEvenShift) ->
    if doYouEvenShift && !AblyTestEnvironment
      alert.addClass('is-hidden')
      @shiftContent 'up', alert
      alert.one 'transitionend webkitTransitionEnd oTransitionEnd otransitionend MSTransitionEnd',(e) ->
        alert.remove()
    else
      alert.remove()

  doIhaveBrotherAlerts: (type) ->
    $existingBrotherAlerts = $("[data-js-header-alerts-container]").find('[data-alert-type="' + type + '"]');

    if $existingBrotherAlerts.length > 0 then true else false

  areThereRailsAlerts: ->
    railsFlashes = $('[data-rails-rendered-flash]');

    if railsFlashes.length > 0 then true else false

  bindEventsToAlertsFromRails: ->
    $('[data-rails-rendered-flash]').each ->
      alert = $(this)
      alert.on 'close', (event) ->
        alert.remove()

$ ->
  # Live click handler for all alert buttons is needed in case Rails renders the Flash
  $(document).on 'click', '[data-dismiss="alert"]', ->
    alert = $(this).parents('[data-alert-type]')

    if alert.parent('[data-js-header-alerts-container]').length > 0
      Alert.closeAlert alert, true
    else
      Alert.closeAlert alert, false


  # Fade out success flashes rendered on the page automatically
  window.alertSuccessFadeOut = ->
    $('[data-rails-rendered-flash][data-alert-type="success"], [data-rails-rendered-flash][data-alert-type="info"]').each ->
      $this = $(this)
      return if $this.attr('data-sticky-flash')?
      delay 8000, =>
        if $this.parent('[data-js-header-alerts-container]').length > 0
          Alert.closeAlert $this, true
        else
          Alert.closeAlert $this, false

  window.showHiddenRailsAlerts = ->
    # find success notices rendered by Rails and hide after they have been seen
    alertSuccessFadeOut()

    if Alert.areThereRailsAlerts
      alerts = $('[data-rails-rendered-flash]');

      alerts.each ->
        $this = $(this)
        if $this.parent('[data-js-header-alerts-container]').length > 0
          Alert.shiftContent 'down', $this;
          $this.removeClass('is-hidden');
        else
          $this.removeClass('is-hidden');

  showHiddenRailsAlerts()

  $(window).on 'closealert', -> showHiddenRailsAlerts


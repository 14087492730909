$(document).ready(() => {
  const hiddenDetails = $('#js-expand-pricing');

  if (hiddenDetails.length === 0) {
    return;
  }

  const expandButton = $('#expand-pricing-button');
  const shrinkButton = $('.js-shrink-pricing-button');
  const pricingControls = $('.c-pricing-box__sliders');

  const expandPricing = () => {
    const top = $(window).scrollTop();

    hiddenDetails.slideDown(() => {
      $(window).scrollTop(top);
    });

    expandButton.css('display', 'none');
    pricingControls.addClass('sticky c-pricing-box__sliders--fixed');
  };

  const shrinkPricing = () => {
    hiddenDetails.slideUp();
    expandButton.css('display', '');
    pricingControls.removeClass('sticky c-pricing-box__sliders--fixed');
  };

  expandButton.on('click', expandPricing);
  shrinkButton.on('click', shrinkPricing);
});

/* eslint-disable func-names */
/*

   http://osvaldas.info/caching-svg-sprite-in-localstorage
*/
import svgSprite from '@svg/icons/ai-svgsprite/sprite.symbol.svg';

// eslint-disable-next-line consistent-return
(function(window, document) {
  const file = svgSprite;
  const revision = svgSprite;

  if (!document.createElementNS || !document.createElementNS('http://www.w3.org/2000/svg', 'svg').createSVGRect)
    return true;

  const isLocalStorage = 'localStorage' in window && window.localStorage !== null;
  let request;
  let data;
  const insertIT = function() {
    document.body.insertAdjacentHTML('afterbegin', data);
    document.getElementsByTagName('html')[0].classList.remove('svgsprite-is-loading');
  };
  const insert = function() {
    if (document.body) insertIT();
    else document.addEventListener('DOMContentLoaded', insertIT);
  };

  if (isLocalStorage && localStorage.getItem('inlineSVGrev') == revision) {
    data = localStorage.getItem('inlineSVGdata');
    if (data) {
      insert();
      return true;
    }
  }

  try {
    request = new XMLHttpRequest();
    request.open('GET', file, true);
    request.onload = function() {
      if (request.status >= 200 && request.status < 400) {
        data = request.responseText;
        insert();
        if (isLocalStorage) {
          try {
            localStorage.setItem('inlineSVGdata', data);
            localStorage.setItem('inlineSVGrev', revision);
          } catch (ex) {
            console.warn('Could not store inline SVG data', ex);
          }
        }
      }
    };
    request.send();
  } catch (ex) {
    console.warn('Could not get SVG data', ex);
  }
})(window, document);

(() => {
  function errorMessage(data) {
    try {
      return JSON.parse(data.responseText).error;
    } catch (e) {
      return "Oops, something went wrong on our side. Please try again or <a href='/contact'>contact us</a> for help";
    }
  }

  function handleSsoLoginError(_event, data) {
    $('#sso-login-msgbox[data-dialog-msgbox]').flashMessages({
      template: window.msgboxerror,
      message: errorMessage(data),
    });
  }

  function handleSsoLoginSuccess(_event, data) {
    const method = 'POST';
    const csrfToken = $.rails.csrfToken();
    const csrfParam = $.rails.csrfParam();

    const form = $(`<form method="post" action="${data.redirectTo}"></form>`);

    let metadataInput = `<input name="_method" value="${method}" type="hidden" />`;

    if (csrfParam !== undefined && csrfToken !== undefined && !$.rails.isCrossDomain(data.redirectTO)) {
      metadataInput += `<input name="${csrfParam}" value="${csrfToken}" type="hidden" />`;
    }

    form
      .hide()
      .append(metadataInput)
      .appendTo('body');

    form.submit();
  }

  function removeAlerts() {
    $('.c-alert').remove();
  }

  function setLocation(url) {
    window.location = url;
  }

  $(document)
    .on('ajax:error', '#sso_login', handleSsoLoginError)
    .on('ajax:success', '#sso_login', handleSsoLoginSuccess);

  $(document).on('click', '#login-link', removeAlerts);

  // set globally for stubbing in Cypress
  window.setLocation = setLocation;
})();

/**
 * @todo componentize what's possible instead of
 * loading everything everyhere
 */
import "./bootstrap.scss";
import "bootstrap-sass/assets/javascripts/bootstrap/dropdown";
import "bootstrap-sass/assets/javascripts/bootstrap/tooltip";
import "bootstrap-sass/assets/javascripts/bootstrap/tab";
import "bootstrap-sass/assets/javascripts/bootstrap/collapse";

/**
 * @desc extends boostrap tooltip plugin to allow custom classnames
 * for theming via data-custom-class attrs on customClass options
 * argument.
 * @link https://stackoverflow.com/a/43408046/2801012
 */
/* eslint-disable */
(function($) {
  if (typeof $.fn.tooltip.Constructor === "undefined") {
    throw new Error("Bootstrap Tooltip must be included first!");
  }

  var Tooltip = $.fn.tooltip.Constructor;

  $.extend(Tooltip.DEFAULTS, {
    customClass: ""
  });

  var _show = Tooltip.prototype.show;

  Tooltip.prototype.show = function() {
    _show.apply(this, Array.prototype.slice.apply(arguments));

    if (this.options.customClass) {
      var $tip = this.tip();
      $tip.addClass(this.options.customClass);
    }
  };
})(window.jQuery);
/* eslint-enable */

/**
 * @desc Polyfill all images with data-ofi attribute
 * Avoid applying to all images which can be slow and wastefull
 */
import objectFitImages from "object-fit-images";
import { select, selectAll } from "global/utils/dom";

document.addEventListener("DOMContentLoaded", function polyfillObjectFit() {
  const siteImages = selectAll("[data-ofi]");
  const blogPostCoverImage = select(
    ".ghost-main-content .post-content .kg-image"
  ); // only first
  const targetImages = [...siteImages, blogPostCoverImage].filter(
    i => i !== null
  );
  objectFitImages(targetImages);
});

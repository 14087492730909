import $$dialogBase from './base';

const $$warnAboutDemoKeysDialog = $$dialogBase({
  id: 'warning-private-keys',
  size: 'small',
  theme: 'warning',
  slot: /* html */ `
    <header class="c-dialog__header">
      <h3 class="u-fs-1 u-no-m-b">Demo only API key</h3>
    </header>

    <div class="c-dialog__body">
      <p>This code example uses a temporary key that is rate limited and expires in 4 hours. <a href="/login">Sign in</a> to Ably to use your API keys instead.</p>

      <label class="c-checkbox" for="js-show-demo-keys-warning-checkbox">
        <input id="js-show-demo-keys-warning-checkbox" type="checkbox">
        <span class="c-checkbox__design"></span>
        <span class="c-checkbox__text">Don’t show again</span>
      </label>
    </div>

    <div class="c-dialog__footer">
      <div class="c-button-group" role="group">
        <button class="c-button c-button--primary-ghost" data-close-dialog>Just copy</button>
        <a href="/signup" class="c-button c-button--primary" data-close-dialog>Get a free API key now</a>
      </div>
    </div>
  `,
});

export default $$warnAboutDemoKeysDialog;

/* eslint-disable camelcase */
const $$menutItem = ({ whole_key, isDisabled, friendlyId }) => {
  const li = document.createElement('li');
  const a = document.createElement('a');

  li.classList.add('c-dropdown__item');
  a.classList.add('c-dropdown__link', 'dev-console__app-key', 'u-text-truncate');

  a.dataset.appKey = whole_key;
  a.dataset.appKeyFriendlyId = friendlyId;
  a.dataset.toggle = 'tooltip';

  if (isDisabled) {
    a.title = isDisabled;
    a.classList.add('is-disabled');
  }

  a.innerText = friendlyId;
  li.append(a);

  return li;
};

export default $$menutItem;

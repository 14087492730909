/* global Alert */
/* eslint-disable prefer-destructuring */

/**
 * allows to select and react to app keys fetche from from a provided url
 * @note [1] - still uses Alert global for emmiting reques error
 */
import $$MenuItem from './templates/menu-item';
import $$MenuOptgroup from './templates/menu-optgroup';

import './app-keys-selector.scss';

export default class AppKeysSelector {
  static getBaseElement(config) {
    if (config.inject) {
      return $($('#js-app-keys-selector-template').html());
    }
    return $(config.selector);
  }

  constructor({ selector = '[data-app-keys-selector]', ...config } = {}) {
    this.$el = AppKeysSelector.getBaseElement({ selector, ...config });
    this.$logger = this.$el.find('[data-app-keys-selected-logger]');
    this.url = this.$el.data('app-keys-url');
    this.appKeys = [];
    this.apps = [];
    this.config = {
      onMenuShowCb: () => {},
      onMenuHideCb: () => {},
      onSelectCb: key => key,
      dataSerializer: arr => arr,
      mixClass: '',
      ...config,
    };

    // eslint-disable-next-line
    this.$el[0].__AppKeysSelector = this;
  }

  /**
   * renderes the dropdown, sets events
   * @param {Function} onInitCb [noop] - success callback with response dataå
   */
  init = (onInitCb = () => {}) => {
    this.setupElements();

    if (!this.url) {
      throw new Error('A valid endpoint must be provided via data-app-keys-url attribute');
    } else if (Array.isArray(this.url)) {
      /**
       * If url is an array we're fetching keys for multiple apps
       * @note [1] handleMenuClick still expects a flat array of keys
       */
      const apps = this.url;

      Promise.all(apps.map(app => this.fetchKeys(app.url)))
        .then(results => {
          this.appKeys = results.flat(); // [1]
          this.apps = apps.map((app, i) => ({
            name: app.name,
            keys: results[i],
          }));
          this.handleFetchKeysSuccess({ multiple: true, cb: onInitCb });
        })
        .catch(err => {
          this.handleFetchKeysError(err, onInitCb);
        });
    } else {
      this.fetchKeys(this.url)
        .then(data => {
          this.appKeys = data;
          this.handleFetchKeysSuccess({ multiple: false, cb: onInitCb });
        })
        .catch(err => {
          this.handleFetchKeysError(err, onInitCb);
        });
    }

    return this;
  };

  /**
   * manipulate dom elements for styling/config
   */
  setupElements() {
    this.$el.toggleClass(`c-dropdown--${this.config.size}`, this.config.size);
    this.$el.toggleClass(`c-dropdown--${this.config.theme}`, this.config.theme);
    this.$el.toggleClass(this.config.mixClass, this.config.mixClass);
    this.$logger.attr('data-original-text', this.$logger.textContent);
  }

  /**
   * abstracted promisifed request to fetch api keys for an
   * based on encoded url
   * @param {String} url - Enconded url string. see accounts_helper.rb#account_apps_api_keys
   */
  fetchKeys = url => {
    return new Promise((resolve, reject) => {
      $.get(url)
        .done(data => {
          if (data.length <= 0) {
            resolve([]);
          } else if (!data.flash_message) {
            // compute da
            const computedKeys = data.map(appKey => {
              return {
                ...appKey,
                friendlyId: this.keyFriendlyId(appKey),
              };
            });
            // serialize and save data to instance
            resolve(this.config.dataSerializer(computedKeys));
          } else {
            reject(data);
          }
        })
        .fail(jqXHR => {
          reject((jqXHR && jqXHR.statusText) || 'Network issue');
        });
    });
  };

  /**
   * Handle request error
   * @param {Object} error
   * @callback cb
   */
  handleFetchKeysError(error, cb = () => {}) {
    Alert.error(`Error fetching keys: ${error}. Please try again or <a href="/contact">contact us</a> for assistance`);
    this.renderError();
    cb(error);
  }

  /**
   * Handle request sucess
   * @param {Object} options
   * @param {Boolean} options.multiple
   * @param {Function} options.cb
   */
  handleFetchKeysSuccess({ multiple = false, cb = () => {} } = {}) {
    this.render({ multiple });
    document.dispatchEvent(
      new CustomEvent('AppKeysSelector:load', {
        detail: { appKey: this.appKeys[0] },
      }),
    );
    cb(this.appKeys);
  }

  /**
   * renderes the dropdown, sets events
   */
  render = ({ multiple = false } = {}) => {
    if (this.config.inject) {
      const $target = $(this.config.inject);
      $target.append(this.$el);
    }

    const $button = $('[data-app-keys-menu-trigger]', this.$el);
    const $menu = $('[data-app-keys-menu]', this.$el);
    const self = this;

    let defaultAppKey;
    if (multiple) {
      const sandbox = this.apps.find(app => app.name === 'Sandbox');
      defaultAppKey = sandbox ? sandbox.keys[0] : this.appKeys[0];
    } else {
      defaultAppKey = this.appKeys[0];
    }

    function handleMenuClick(e) {
      e.preventDefault();
      const $this = $(this);
      if ($this.hasClass('is-disabled')) return;

      $('[data-app-key]').removeClass('is-selected');
      $this.addClass('is-selected');

      const friendlyId = $this.data('app-key-friendly-id');
      const appWholeKey = $this.data('app-key');
      const appKey = self.appKeys.find(k => k.whole_key === appWholeKey);

      self.$logger.text(friendlyId);

      document.dispatchEvent(
        new CustomEvent('AppKeysSelector:select', {
          detail: { appKey },
        }),
      );

      self.config.onSelectCb(appKey);
    }
    // in case of reload, remove events and empty already loaded options
    $menu.off('click', handleMenuClick);
    $menu.empty();

    // set label to the first loaded key
    this.$logger.text(defaultAppKey?.friendlyId || 'Demo Key');
    // build the ui
    if (multiple) {
      this.apps.forEach(app => {
        $menu.append(
          $$MenuOptgroup({
            keys: app.keys,
            label: app.name,
          }),
        );
      });
    } else {
      this.appKeys.forEach(appKey => {
        $menu.append($$MenuItem(appKey));
      });
    }

    // set selected state for default selected key
    // eslint-disable-next-line camelcase
    $(`[data-app-key="${defaultAppKey?.whole_key}"]`).addClass('is-selected');

    // execute default selection
    self.config.onSelectCb(defaultAppKey || { whole_key: $('[data-demo-api-key]').data('demo-api-key') });

    // init tooltips if present in menu item
    $('[data-toggle="tooltip"]').tooltip();

    // callbacks on open/close menu
    $(this.$el).on('show.bs.dropdown', () => {
      this.config.onMenuShowCb(this);
    });
    $(this.$el).on('hidden.bs.dropdown', () => {
      this.config.onMenuHideCb(this);
    });

    $button.removeClass('disabled');
    $menu.on('click', '[data-app-key]', handleMenuClick);
  };

  renderError() {
    const cb = e => {
      e.stopPropagation();
      this.$logger.textContent = this.$logger.data('originalText') || 'Loading...';
      this.init();
    };
    this.$logger.off('click', cb);
    this.$logger.text('Retry loading');
    this.$logger.on('click', cb);
  }

  /**
   * displays a human readable name for selection options
   */
  keyFriendlyId = appKey => {
    return `${appKey.whole_key.split(':')[0]} - ${appKey.name}`;
  };
}

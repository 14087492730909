import $$menuItem from './menu-item';

const $$menuOptgroup = ({ label, keys }) => {
  const li = document.createElement('li');
  const span = document.createElement('span');
  const ul = document.createElement('ul');

  li.classList.add('c-dropdown__optgroup');

  span.classList.add('c-dropdown__item', 'c-dropdown__item--optgroup-label');
  span.id = `${label}-keys-list`;
  span.innerText = label;

  ul.classList.add('c-dropdown__optgroup');
  ul.setAttribute('aria-labelledby', `${label}-keys-list`);
  Array.from(keys).forEach(key => ul.append($$menuItem(key)));

  li.append(span);
  li.append(ul);

  return li;
};

export default $$menuOptgroup;

/* global PasswordStrength, PASSWORD_MIN_LENGTH */

/* eslint-disable no-underscore-dangle */
PasswordStrength.prototype._test = PasswordStrength.prototype.test;

// eslint-disable-next-line func-names
PasswordStrength.prototype.test = function() {
  this.score = 0;

  if (this.password.length < PASSWORD_MIN_LENGTH || this.containInvalidMatches()) {
    this.status = 'invalid';
  } else {
    if (/[A-Z]+/.test(this.password)) this.score += 1;
    if (/[a-z]+/.test(this.password)) this.score += 1;
    if (/[0-9]+/.test(this.password)) this.score += 1;
    if (this.scoreFor('symbols') > 0) this.score += 1;
  }

  if (this.score < 2 || this.scoreFor('username') < 0) this.status = 'invalid';

  if (this.score < 0) {
    this.score = 0;
  }

  if (this.score > 4) {
    this.score = 4;
  }

  if (this.score < 2) {
    this.status = 'weak';
  }

  if (this.score >= 2 && this.score < 4) {
    this.status = 'good';
  }

  if (this.score >= 4) {
    this.status = 'strong';
  }

  return this.score;
};

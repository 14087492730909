/* globals bodyScrollFreezer, grecaptcha */
/* eslint-disable func-names */

/*
  Add new class modifier .fancybox-inner--responsive to inner content
  Makes the component responsive
*/
$('[data-fancybox-responsive]').fancybox({
  tpl: {
    wrap:
      '<div class="fancybox-wrap" tabIndex="-1" role="dialog"><div class="fancybox-skin"><div class="fancybox-outer"><div class="fancybox-inner fancybox-inner--responsive"></div></div></div></div>',
  },
  helpers: {
    overlay: {
      opacity: 0.3,
      closeClick: true,
    },
  },
});

// [1]. optionally block body scroll
$('[data-fancybox-type]').fancybox({
  ajax: { data: { nolayout: 'yes' } },
  padding: 0,
  minWidth: 798,
  maxWidth: 798,
  scrolling: 'no',
  fitToView: false,
  closeBtn: false,
  wrapCSS: '',
  tpl: {
    closeBtn: `<button class="c-button c-button--close-lg c-button--close-raised c-dialog__close-btn" data-close-dialog>
      <svg class="c-button__icon ai ai-close-hairline" role="presentation">
        <use xlink:href="#ai-close-hairline"></use>
      </svg>
      <span class="u-sr-only">Close Dialog</span>
    </button>`,
  },
  helpers: {
    overlay: {
      opacity: 0.3,
      closeClick: false,
    },
  },
  afterClose() {
    const $content = $(this.content);
    if ($content.data('dirty')) {
      $content.load($content.data('dirty'), function() {
        $content.removeData('dirty');
      });
    }
    window.location.hash = '';

    $('[data-close-dialog]').off('click');

    // [1]
    if ($(this.element).data('fancybox-overlay-lock') === true) {
      bodyScrollFreezer.toggleFreeze();
    }
  },
  afterLoad() {
    const $content = $(this.content);
    $content.data('tmpl', $content.clone().html());
    if (!window.AblyTestEnvironment && !window.disableRecaptcha && typeof grecaptcha !== 'undefined') {
      grecaptcha.ready(function() {
        const recaptchaKey = $('#user_recaptcha').data('recaptchakey');
        // We have to recreate the recaptcha div element since recaptcha can't be rendered twice
        $('#user_recaptcha').remove();
        $('.js-recaptcha-wrapper').prepend(
          `<div class="g-recaptcha" data-recaptchakey="${recaptchaKey}" id="user_recaptcha"></div>`,
        );
        // Render recaptcha
        const captchaId = grecaptcha.render('user_recaptcha', {
          sitekey: $('#user_recaptcha').data('recaptchakey'),
        });
        // Save the captcha ID to user when resetting the recaptcha
        $('#user_recaptcha').attr('captcha-id', captchaId);
      });
    }
  },
  afterShow() {
    $.fancybox.reposition();
  },
  beforeShow() {
    // [1]
    if ($(this.element).data('fancybox-overlay-lock') === true) {
      bodyScrollFreezer.toggleFreeze();
    }
    $('[data-close-dialog]').on('click', function() {
      $.fancybox.close();
    });

    $('.hide-on-load').hide();
    $('.show-on-load').show();
  },
});

window.open_dialog = function(msg = 'Give me some content!', options = {}) {
  const o = $.extend(
    {},
    {
      padding: 0,
      scrolling: 'no',
      minWidth: 600,
      fitToView: false,
      helpers: {
        overlay: {
          opacity: 0.3,
        },
      },
      content: `<div id="message-box" class="c-dialog c-dialog--small">\
                  <div class="c-dialog__bar" data-layout="end middle"><button class="c-button c-button--close-lg c-dialog__close-btn" data-close-dialog><svg class="c-button__icon ai ai-close-hairline" role="presentation"> <use xlink:href="#ai-close-hairline"></use></svg><span class="u-sr-only">Close Dialog</span></button></div>\
                    ${msg}\
                </div>`,
    },
    options,
  );

  $.fancybox(o);
};

$('[data-close-dialog]').on('click', function() {
  $.fancybox.close();
});

window.msgboxerror = '<div class="c-alert" data-alert-type="error">\
                  <p class="c-alert__msg">{MSG}</p>\
                </div>'
window.msgboxnotice = '<div class="c-alert" data-alert-type="notice">\
                  <p class="c-alert__msg">{MSG}</p>\
                </div>'
window.pageblocker = '<div style="z-index:10000;position:fixed;top:0;left:0;width:100%;height:100%;"><div style="position:absolute;z-index:0;top:0;left:0;width:100%;height:100%;background:white;opacity:.8"></div><div style="position:absolute;z-index:1;top:50%;left:50%;width:450px;margin-left:-225px;margin-top:-50px"><p style="position:relative;margin:auto;color:#6e7d9a;font-size:30px;">{MSG}</p><div class="loading-icon64" style="display:block;margin-top:20px"></div></div></div>'
window.offset = $('[data-js-header-wrap]').height()

ajaxAbortTimer = 0
getElem = (target) ->
  if target.tagName.toLowerCase() == 'form'
    $('[type="submit"]', target )
  else
    target
getForm = (target) ->
  if target.tagName.toLowerCase() == 'form'
    target
  else
    $(target).closest('form')[0]

$(document)
  .on 'keyup', '[data-hook="strength"]' , ->
    _self=$(this)
    val = _self.val()
    className = 'password-strength'

    if val.length == 0

      _self.nextAll('.'+className).remove()

    if val.length > 0

      if _self.next('.error-message').length
        _self.next('.error-message').remove()

      strength=PasswordStrength.test($('#user_email').val(), _self.val())

      strength_string=""
      if (strength.isGood())
        strength_string="OK"
        _self.removeClass('error')
      else if (strength.isWeak())
        strength_string="Weak"
        _self.addClass('error')
      else if (strength.isStrong())
        strength_string="Strong"
        _self.removeClass('error')

      ele=$("<span class=\"#{className} ui-text-p2 text-charcoal-grey font-bold\">Password strength: <span class=\"indicator ui-text-p2 text-active-orange font-bold\">#{strength_string}</span></span>").addClass(strength_string.toLowerCase())
      existing_ele=_self.nextAll('.'+className)
      if (existing_ele.length>0)
          existing_ele.replaceWith(ele)
      else
          _self.after(ele)

  .on 'blur', '[name="user\[email\]"]', ->
    $(document).data( 'session_email', $(this).val() ) unless $(this).val() == ""

  .on 'ajax:before', ( event ) ->
    elem = $(getElem(event.target))
    # exit if loading-icon found
    return if elem.parent().find(".loading-icon").length
    elem.parent().append('<span class="loading-icon"></span>').find('.loading-icon').css({ height: elem.outerHeight(true) });

  # switch off default ujs handling of ajax:completed forms
  .off('ajax:complete.rails')

  .on 'ajax:complete', ( event, xhr, status ) ->
    $form = $( getForm(event.target) )
    $elem = $( getElem(event.target) )
    if !!$form.data('redirect') && status != 'error'
      window.location=xhr.getResponseHeader('location') || $form.data('redirect')
    else if xhr.responseJSON && xhr.responseJSON.redirect && status != 'error'
      window.location=xhr.responseJSON.redirect
    else
      $.rails.enableFormElements( $form )
      $elem.siblings('.loading-icon').remove()
    clearTimeout(ajaxAbortTimer)

  .on 'ajax:beforeSend', (event, xhr) ->
    currentXhr = xhr
    elem = getElem( event.target )
    ajaxAbortTimer = setTimeout( () ->
      currentXhr.abort()
      $(elem).removeAttr('disabled').siblings('.loading-icon').remove()
      $('.fancybox-opened [data-dialog-msgbox]').flashMessages({
        message: "Oops, it looks like there are connectivity issues to the Ably servers. Please check your connection and try again",
        template: msgboxerror,
        afterShow: () ->
          $.fancybox.reposition()
      })
    , 30000)

  # TODO: Remove after moving to Stripe
  # this generates a block overlay and a purpose 2 second delay before proceeding to payment
  .on('click.proceed','[data-hook="proceed_to_payment"]', (event) ->
    $this = $(window)
    url = $this.attr('href')
    $('body').append(window.pageblocker.replace('{MSG}', 'Please wait while we send you<br>off to our payment provider...'))
    setTimeout(->
      window.location.href = url
    ,2000)
    false
  )

import $$dialogBase from "./base";

const $$warnAboutPrivateKeysDialog = $$dialogBase({
  id: "warning-private-keys",
  size: "small",
  theme: "warning",
  slot: /* html */ `
    <header class="c-dialog__header">
      <h3 class="u-fs-1 u-no-m-b">Private API Keys</h3>
    </header>

    <div class="c-dialog__body">
      <p>The code you’ve just copied includes one of your Ably Private API Keys.
      You should not share API keys with others, and should instead use tokens. Find out more about authentication tokens.</p>

      <label class="c-checkbox" for="js-show-private-keys-warning-checkbox">
        <input id="js-show-private-keys-warning-checkbox" type="checkbox">
        <span class="c-checkbox__design"></span>
        <span class="c-checkbox__text">Don’t show again</span>
      </label>
    </div>

    <div class="c-dialog__footer">
      <button class="c-button c-button--primary" data-close-dialog>OK</button>
    </div>
  `
});

export default $$warnAboutPrivateKeysDialog;
